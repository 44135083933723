import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'
import { uploadFileItem } from './base'

interface saveReq {
  id?: string | number // id
  staff_id?: string // 提交人ID
  project_id: string // 项目id
  date: string // 验收时间
  explain?: string // 说明
  pics?: Array<uploadFileItem> // 说明
}
export type saveProject = saveReq //导出请求保存的数据结构

// 项目验收 添加接口
export function AcceptanceSave(data: saveReq) {
  const url = '/api/project_acceptance/save'
  return cateringRequest.post(url, data)
}
